.dashboard {
    .large-text {
        font-size: 4em;
        line-height: 1em;
        font-weight: bold;
        padding: 0;
        margin: 0;
    }

    .publish {
        color: blue;
    }
    .unpublish {
        color: red;
    }

    button {
        min-width:150px;
        &.btn-small {
            min-width: 100px;
        }
    }

    .downloading-survey-details {
        display:inline-block;
    }
}
