.surveys {
    a {
        font-size: 16px !important;
    }
    .large-text {
        font-size: 4em;
        line-height: 1em;
        font-weight: bold;
        padding: 0;
        margin: 0;
    }

    .publish {
        color: blue;
    }
    .unpublish {
        color: $danger;
    }

    button {
        min-width: 150px;
        &.btn-small {
            min-width: 100px;
        }
        &.nav-arrow {
            min-width: 40px;
        }
        &.btn-link {
            &:disabled {
                opacity: 0.3;
            }
        }
    }

    .downloading-survey-details {
        display: inline-block;
    }

    .arrow {
        border: solid $portal-color;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 5px;
        &.right {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }

        &.left {
            margin-left: 10px;
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
        }
    }
}
