.placeholder {
    color: #677079;
    min-height: 1em;
    cursor: wait;
    background-color: currentColor;
    margin-bottom: 0.25em;

    &.glow {
        animation: placeholder-glow 2s ease-in-out infinite;
    }
    &.inline {
        display: inline-block;
    }
}

@keyframes placeholder-glow {
    50% {
        opacity: 0.2;
    }
}