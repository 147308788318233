* {
    box-sizing: border-box;
}

body {
    font-size: map-get($font-size, content);
    background-color: $portal-color-lightgrey;
    button,
    a {
        font-size: map-get($font-size, content);
    }
}

.bg-light {
    background: #dfdfdf !important;
}

.name {
    text-transform: capitalize;
}

.environment-indicator {
    position: absolute;
    right: 0;
    z-index: 999;
}

.container-small {
    max-width: $container-width;
    background-color: #fff;
    border-radius: 20px;
    padding: 40px;
    min-height: 500px;
    margin-right: auto;
    margin-left: auto;
    box-shadow: 1px 1px 2px rgb(160, 158, 158);
}

.global-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(#eee, 0.5);
    border-radius: 3px;
    .text-primary {
        color: $portal-color !important;
    }
}

.btn-small {
    font-size: 11px !important;
    margin-top: 4px;
}

.file-download-container {
    position:fixed;
    padding: 20px;
    color:#000 !important;
    left: 0;
    right: 0;
    border-top: 0.25rem solid #e3e7eb;
    background-color:#FFE8AC !important;
    bottom: -120px;
    bottom: 0px;
}

.legal-content-container {
   min-height: 30vh;
   overflow-wrap: break-word;
}

.cursor-pointer {
    cursor: pointer;
}