.signup-progress {
    position: relative;
    .signup-progress-number {
        position: relative;
        z-index: 1;
        font-size: 28px;
        border: 3px solid #dfdfdf;
        border-radius: 50%;
        height: 48px;
        width: 48px;
        margin: 0 auto 12px auto;
        background: white;
        color: #dfdfdf;
        &.active {
            border: 3px dotted #dfdfdf;
        }
        &.complete {
            background: #efefef;
            border: 3px solid #cfcfcf;
            color: #afafaf;
        }
        @media only screen and (max-width: 330px) {
            height: 36px;
            width: 36px;
            font-size: 20px;
        }
    }
    .signup-progress-label {
        font-size: 12px;
        line-height: 14px;
        width: 80px;
        margin: 0 auto;
        @media only screen and (max-width: 330px) {
            width: 100%;
        }
    }
    &:after {
        content: "";
        width: 100%;
        display: block;
        background: #dfdfdf;
        height: 3px;
        position: absolute;
        top: 24px;
        left: 0;
        @media only screen and (max-width: 330px) {
            top: 18px;
        }
    }
}