
@import 'styles/variables.scss';
@import 'styles/mixins.scss';

@import 'styles/bootstrap-overrides.scss';

@import "~bootstrap/scss/bootstrap";

@import 'styles/bootstrap-custom-styles.scss';

@import 'styles/reset.scss';
@import 'styles/global.scss';
@import 'styles/navigation.scss';
@import 'styles/button.scss';

@import 'styles/components/loading.scss';
@import 'styles/components/signup-progress.scss';
@import 'styles/components/surveys.scss';
@import 'styles/components/dashboard.scss';
@import 'styles/components/users.scss';

@import 'styles/theme/sb-admin-2.scss';
@import 'styles/theme/login.scss';