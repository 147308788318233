a:focus {
  @include focus-box-shadow($primary, 'ignore');
  border-radius: 5px;
}

.navbar-light {
  a:focus {
    @include focus-box-shadow($reverse, 'ignore');
  }
}

.btn.btn-primary:focus {
  @include focus-box-shadow();
}

.btn.btn-outline-primary:focus {
  @include focus-box-shadow($primary, 'ignore');
}

.btn.btn-info:focus {
  @include focus-box-shadow($info);
}

.btn.btn-outline-info:focus {
  @include focus-box-shadow($info, 'ignore');
}

.btn.btn-outline-danger:focus {
  @include focus-box-shadow($danger, 'ignore');
}

.btn.btn-light:focus {
  @include focus-box-shadow($gray-600);
  border-color: $gray-600;
}

.btn.btn-secondary:focus {
  @include focus-box-shadow($secondary);
}

.custom-select:focus {
  @include focus-box-shadow($primary, 'ignore', true); 
}

.btn.btn-link:focus {
  @include focus-box-shadow($primary, 'ignore');
}